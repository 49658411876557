import { Navigate, useLocation } from "react-router-dom";
import Login from "./authantication/login";
import { Auth_Key } from "./helpers/authToken";

export const PrivateRoutes = ({ children }) => {
    let token = localStorage.getItem('authToken')
    if (token) {
        return <Navigate to="/salesorder" />;
    }
    else return children

};