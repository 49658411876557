import axios from "axios";
import { dashboardService } from "../shared/services";
import { API_URL, Authorization, Auth_Key, checkIsValidToken } from "../helpers/authToken";
import { useNavigate } from "react-router-dom";
import history from "../History";

//login
export const login = (data, next) => (dispatch) => {
    dispatch({ type: "LOGIN_REQUEST" })
    dashboardService
        .userLoginData(data)
        .then((res) => {
            if (res.data.flag) {
                localStorage.setItem('authToken', res.data.result.data.token);
                localStorage.setItem('role', res.data.result.data.role);
                dispatch({
                    type: "LOGIN_SUCCESS",
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: "LOGIN_ERROR",
                    error: res.data.message,
                });
            }
            next(res.data);
        })
        .catch((error) => {
            if (error.response && error.response.status === 401) {
                dispatch({
                    type: "LOGIN_ERROR",
                    error: error.response.data.message,
                });
            }
        });
};

//logout
export const logout = () => (dispatch) => {
    dashboardService
        .logoutData()
        .then((res) => {
            if (res.data.flag) {
                localStorage.clear()
                dispatch({
                    type: "LOGOUT_SUCCESS",
                    payload: res.data,
                });
                dispatch({
                    type: "GET_SALESORDER_LIST_DATA_SUCCESS",
                    payload: null
                })
            }
            // next(res.data.result);
        })
        .catch((error) => {
            if (error.response && error.response.status === 401) {
                dispatch({
                    type: "LOGOUT_ERROR",
                    error: error.response,
                });
            }
        });
};

//forget password

export function forgetPassword(data, next) {
    return (dispatch) => {
        dispatch({ type: "LOADING", payload: true });

        dashboardService
            .forgetPasswordData(data)
            .then((res) => {
                dispatch({ type: "LOADING", payload: false });
                dispatch({ type: "FORGET_PASSWORD_SUCCESS", payload: res.data.result });
                next(res.data);
            })
            .catch((e) => { });
    };
};


// update profile

export function updateUserInfo(data, next) {
    return (dispatch) => {
        dispatch({ type: "LOADING", payload: true });

        dashboardService
            .updateUserProfileData(data)
            .then((res) => {
                dispatch({ type: "LOADING", payload: false });
                dispatch({ type: "UPDATE_PROFILE_SUCCESS", payload: res.data.result });
                next(res.data);
            })
            .catch((e) => { });
    };
};

// change password

export function resetLoginPassword(data, next) {
    return (dispatch) => {
        dispatch({ type: "LOADING", payload: true });

        dashboardService
            .resetPasswordData(data)
            .then((res) => {
                dispatch({ type: "LOADING", payload: false });
                dispatch({ type: "UPDATE_PASSWORD_SUCCESS", payload: res.data.result });
                next(res.data);
            })
            .catch((e) => { });
    };
}

//forget password change

export function updateForgetPassword(data, next) {
    return (dispatch) => {
        dispatch({ type: "LOADING", payload: true });

        dashboardService
            .updatePasswordData(data)
            .then((res) => {
                dispatch({ type: "LOADING", payload: false });
                dispatch({ type: "RESET_PASSWORD_SUCCESS", payload: res.data.result });
                next(res.data);
            })
            .catch((e) => { });
    };
}

// user module

// for load user data
export function getUserList(data, next) {
    return (dispatch) => {
        checkIsValidToken(history)
        dispatch({ type: "LOADING", payload: true });

        dashboardService
            .userData(data)
            .then((res) => {
                if (res.response && res.response.status === 401) {
                    Authorization(history)
                } else {
                    dispatch({ type: "LOADING", payload: false });
                    dispatch({ type: "GET_USER_LIST_DATA_SUCCESS", payload: res.data.result });
                    next(res.data.result.data);
                }
            })
            .catch((err) => { });
    };
}

export function createUser(data, next) {
    return (dispatch) => {
        dispatch({ type: "LOADING", payload: true });
        checkIsValidToken(history)

        dashboardService
            .createUserData(data)
            .then((res) => {
                dispatch({ type: "LOADING", payload: false });
                dispatch({ type: "CREATE_USER_DATA_SUCCESS", payload: res.data.result });
                next(res);
            })
            .catch((e) => { Authorization(history) });
    };
}

export function fetchUser(id, next) {
    return (dispatch) => {
        dispatch({ type: "LOADING", payload: true });
        checkIsValidToken(history)

        dashboardService
            .fetchUserData(id)
            .then((res) => {
                dispatch({ type: "LOADING", payload: false });
                dispatch({ type: "FETCH_USER_DATA_SUCCESS", payload: res.data.result });
                next(res.data.result.data);
            })
            .catch((e) => { Authorization(history) });
    };
}

export function updateUser(data, id, next) {
    return (dispatch) => {
        dispatch({ type: "LOADING", payload: true });
        checkIsValidToken(history)

        dashboardService
            .updateUserData(data, id)
            .then((res) => {
                dispatch({ type: "LOADING", payload: false });
                dispatch({ type: "UPDATE_USER_DATA_SUCCESS", payload: res.data.result });
                next(res.data);
            })
            .catch((e) => { Authorization(history) });
    };
}

export function deleteUser(id, next) {
    return (dispatch) => {
        dispatch({ type: "LOADING", payload: true });
        checkIsValidToken(history)

        dashboardService
            .deleteUserData(id)
            .then((res) => {
                dispatch({ type: "LOADING", payload: false });
                dispatch({ type: "DELETE_USER_DATA_SUCCESS", payload: res.data.result });
                next(res.data);
            })
            .catch((e) => { Authorization(history) });
    };
}


// customer module


export function getCustomerList(data, next) {
    return (dispatch) => {
        dispatch({ type: "LOADING", payload: true });
        checkIsValidToken(history)

        dashboardService
            .customerData(data)
            .then((res) => {
                if (res.response && res.response.status === 401) {
                    Authorization(history)
                } else {
                    dispatch({ type: "LOADING", payload: false });
                    dispatch({ type: "GET_CUSTOMER_LIST_DATA_SUCCESS", payload: res.data.result });
                    next(res.data.result.data);
                }
            })
            .catch((e) => { Authorization(history) });
    };
}

export function createCustomer(data, next) {
    return (dispatch) => {
        dispatch({ type: "LOADING", payload: true });
        checkIsValidToken(history)

        dashboardService
            .createCustomerData(data)
            .then((res) => {
                dispatch({ type: "LOADING", payload: false });
                dispatch({ type: "CREATE_CUSTOMER_DATA_SUCCESS", payload: res.data.result });
                next(res);
            })
            .catch((e) => { Authorization(history) });
    };
}

export function fetchCustomer(id, next) {
    return (dispatch) => {
        dispatch({ type: "LOADING", payload: true });
        checkIsValidToken(history)

        dashboardService
            .fetchCustomerData(id)
            .then((res) => {
                dispatch({ type: "LOADING", payload: false });
                dispatch({ type: "FETCH_CUSTOMER_DATA_SUCCESS", payload: res.data.result });
                next(res.data.result.data);
            })
            .catch((e) => { Authorization(history) });
    };
}

export function updateCustomer(data, id, next) {
    return (dispatch) => {
        dispatch({ type: "LOADING", payload: true });
        checkIsValidToken(history)

        dashboardService
            .updateCustomerData(data, id)
            .then((res) => {
                dispatch({ type: "LOADING", payload: false });
                dispatch({ type: "UPDATE_CUSTOMER_DATA_SUCCESS", payload: res.data.result });
                next(res.data);
            })
            .catch((e) => { Authorization(history) });
    };
}

export function deleteCustomer(id, next) {
    return (dispatch) => {
        dispatch({ type: "LOADING", payload: true });
        checkIsValidToken(history)

        dashboardService
            .deleteCustomerData(id)
            .then((res) => {
                dispatch({ type: "LOADING", payload: false });
                dispatch({ type: "DELETE_CUSTOMER_DATA_SUCCESS", payload: res.data.result });
                next(res.data);
            })
            .catch((e) => { Authorization(history) });
    };
}

// sales order module


export function getSalesOrderList(data, next) {
    return (dispatch) => {
        dispatch({ type: "LOADING", payload: true });
        checkIsValidToken(history)

        dashboardService
            .salesOrderData(data)
            .then((res) => {
                if (res.response && res.response.status === 401) {
                    Authorization(history)
                    // dispatch({ type: "LOADING", payload: true });
                    // toast.success(res.response.data.message, { position: toast.POSITION.TOP_RIGHT, });
                } else {
                    dispatch({ type: "LOADING", payload: false });
                    dispatch({ type: "GET_SALESORDER_LIST_DATA_SUCCESS", payload: res.data });
                    next(res.data.result.data);
                }
            })
            .catch((e) => { Authorization(history) });
    };
}

export function createSalesOrder(data, next) {
    return (dispatch) => {
        dispatch({ type: "LOADING", payload: true });
        checkIsValidToken(history)

        dashboardService
            .createSalesOrderData(data)
            .then((res) => {
                dispatch({ type: "LOADING", payload: false });
                dispatch({ type: "CREATE_SALESORDER_DATA_SUCCESS", payload: res.data });
                next(res.data);
            })
            .catch((e) => { Authorization(history) });
    };
}

export function fetchSalesOrder(data, next) {
    return (dispatch) => {
        dispatch({ type: "LOADING", payload: true });
        checkIsValidToken(history)

        dashboardService
            .fetchSalesOrderData(data)
            .then((res) => {
                dispatch({ type: "LOADING", payload: false });
                dispatch({ type: "FETCH_SALESORDER_DATA_SUCCESS", payload: res.data });
                next(res.data.result.data);
            })
            .catch((e) => { Authorization(history) });
    };
}

export function updateSalesOrder(data, id, next) {
    return (dispatch) => {
        dispatch({ type: "LOADING", payload: true });
        checkIsValidToken(history)

        dashboardService
            .updateSalesOrderData(data, id)
            .then((res) => {
                dispatch({ type: "LOADING", payload: false });
                dispatch({ type: "UPDATE_SALESORDER_DATA_SUCCESS", payload: res.data });
                next(res.data);
            })
            .catch((e) => { Authorization(history) });
    };
}

export function deleteSalesOrder(data, next) {
    return (dispatch) => {
        dispatch({ type: "LOADING", payload: true });
        checkIsValidToken(history)

        dashboardService
            .deleteSalesOrderData(data)
            .then((res) => {
                dispatch({ type: "LOADING", payload: false });
                dispatch({ type: "DELETE_SALESORDER_DATA_SUCCESS", payload: res.data.result });
                next(res.data);
            })
            .catch((e) => { Authorization(history) });
    };
}

export function deleteItem(data, next) {
    return (dispatch) => {
        dispatch({ type: "LOADING", payload: true });
        checkIsValidToken(history)

        dashboardService
            .deleteItemData(data)
            .then((res) => {
                dispatch({ type: "LOADING", payload: false });
                dispatch({ type: "DELETE_ITEM_DATA_SUCCESS", payload: res.data.result });
                next(res.data);
            })
            .catch((e) => { Authorization(history) });
    };
}

//get customer list in sales order

export function getCustomerInSales(next) {
    return (dispatch) => {
        dispatch({ type: "LOADING", payload: true });
        checkIsValidToken(history)

        dashboardService
            .getCustomer()
            .then((res) => {
                dispatch({ type: "LOADING", payload: false });
                dispatch({ type: "GET_CUSTOMER_SUCCESS", payload: res.data });
                next(res.data.result.data);
            })
            .catch((e) => { });
    };
}

export function getSalesPerson(next) {
    return (dispatch) => {
        dispatch({ type: "LOADING", payload: true });
        checkIsValidToken(history)

        dashboardService
            .getSalesPerson()
            .then((res) => {
                dispatch({ type: "LOADING", payload: false });
                dispatch({ type: "GET_SALES_PERSON_SUCCESS", payload: res.data });
                next(res.data.result.data);
            })
            .catch((e) => { });
    };
}

export function getPreviewPdf(id, next) {
    return (dispatch) => {
        dispatch({ type: "LOADING", payload: true });
        checkIsValidToken(history)

        dashboardService
            .getPreviewData(id)
            .then((res) => {
                dispatch({ type: "LOADING", payload: false });
                dispatch({ type: "GET_PREVIEW_PDF_SUCCESS", payload: res.data });
                next(res.data.result.data);
            })
            .catch((e) => { });
    };
}

//upload file

export function uploadFileData(fileData, documentType, next) {
    return (dispatch) => {
        dispatch({ type: "LOADING", payload: true });
        checkIsValidToken(history)
        let formData = new FormData();
        formData.append('file', fileData.file);

        dashboardService
            .uploadFile(formData, documentType)
            .then((res) => {
                dispatch({ type: "LOADING", payload: false });
                dispatch({ type: "UPLOAD_FILE_DATA_SUCCESS", payload: res.data });
                next(res.data);
            })
            .catch((e) => { });
    };
}

export function deleteUploadFile(id, next) {
    return (dispatch) => {
        dispatch({ type: "LOADING", payload: true });
        checkIsValidToken(history)

        dashboardService
            .deleteFile(id)
            .then((res) => {
                dispatch({ type: "LOADING", payload: false });
                dispatch({ type: "DELETE_FILE_DATA_SUCCESS", payload: res.data });
                next(res.data);
            })
            .catch((e) => { });
    };
}

export function downloadUploadedFile(id, next) {
    return (dispatch) => {
        dispatch({ type: "LOADING", payload: true });
        checkIsValidToken(history)

        dashboardService
            .downloadFile(id)
            .then((res) => {
                dispatch({ type: "LOADING", payload: false });
                dispatch({ type: "DOWNLOAD_FILE_DATA_SUCCESS", payload: res.data.result });
                next(res.data.result);
            })
            .catch((e) => { });
    };
}

