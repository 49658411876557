import React, { Component } from 'react';
import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import history from "./History";
import configureStore from './store';
import { ProtectedRoute } from './ProtectedRoutes';
import { PrivateRoutes } from './PrivateRoutes';
import ResetPassword from './authantication/ResetPassword';


const store = configureStore();
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

const Login = React.lazy(() => import('./authantication/login'));
const ForgetPassword = React.lazy(() => import('./authantication/ForgetPassword'));
const DefaultLayout = React.lazy(() => import('./container/defaultLayout'));

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter history={history}>
          <React.Suspense fallback={loading()}>
            <Routes>
              <Route exact path="/" name="Login" element={
                <PrivateRoutes  >
                  <Login />
                </PrivateRoutes>
              } />
              <Route exact path="/forget" name="ForgetPassword" element={<ForgetPassword />} />
              <Route exact path="*" name="Home"
                element={
                  <ProtectedRoute >
                    <DefaultLayout />
                  </ProtectedRoute>
                } />
                <Route exact path="/reset/:id" name="Resetpassword" element={<ResetPassword />} />
            </Routes>
          </React.Suspense>
        </BrowserRouter>
      </Provider>
    )
  }
}

export default App;