import { Auth_Key } from "../../helpers/authToken"
import { setToken } from "../../shared/services"

const initialState = {
    sidebarShow: true,
    unfoldable: false,

    loginLoader: false,
    loginData: null,
    loginDataErr: null,

    logoutData: null,
    logoutDataErr: null,

    updatedProfileData: null,
    updateProfileError: null,

    updatedPassword: null,
    updatePasswordError: null,

    resetPassword:null,

    forgetPassword: null,

    getUserListData: null,

    createUserData: null,

    fetchUserData: null,

    updateUserData: null,

    deleteUserData: null,

    getCustomerListData: null,

    createCustomerData: null,

    fetchCustomerData: null,

    updateCustomerData: null,

    deleteCustomerData: null,

    getSalesOrderListData: null,

    createSalesOrderData: null,

    fetchSalesOrderData: null,

    updateSalesOrderData: null,

    deleteSalesOrderData: null,

    deleteItemData: null,

    getCustomer: null,

    getSalesPersonData: null,

    getPreviewPdfData: null,

    filter: {
        page: 0,
        limit: 0,
    },

    uploadFile: null,

    deleteFile: null,

    downloadFile: null

}

export default function app(state = initialState, { type, ...rest }) {
    switch (type) {
        case 'set':
            return { ...state, ...rest }

        //login

        case 'LOGIN_REQUEST':
            return {
                ...state,
                loginLoader: true
            }

        case 'LOGIN_SUCCESS':
            setToken(rest.payload ? rest.payload.result.data.token : localStorage.getItem('authToken'))
            return {
                ...state,
                loginData: rest.payload,
                loginLoader: true
            }

        case 'LOGIN_ERROR':
            return {
                ...state,
                loginDataErr: rest.error,
                loginLoader: true
            }

        //logout

        case 'LOGOUT_SUCCESS':
            return {
                ...state,
                logoutData: rest.payload,
            }

        case 'LOGOUT_ERROR':
            return {
                ...state,
                logoutDataErr: rest.error,
            }

        // UPDATE PROFILE

        case 'UPDATE_PROFILE_SUCCESS':
            return {
                ...state,
                updatedProfileData: rest.payload
            }

        //forget password

        case 'FORGET_PASSWORD_SUCCESS':
            return {
                ...state,
                forgetPassword: rest.payload
            }

        //reset password

        case 'UPDATE_PASSWORD_SUCCESS':
            return {
                ...state,
                updatedPassword: rest.payload
            }

        //UPDATE password

        case 'RESET_PASSWORD_SUCCESS':
            return {
                ...state,
                resetPassword: rest.payload
            }
        // get userlist

        case 'GET_USER_LIST_DATA_SUCCESS':
            return {
                ...state,
                getUserListData: rest.payload
            }

        //create user

        case 'CREATE_USER_DATA_SUCCESS':
            return {
                ...state,
                createUserData: rest.payload
            }

        //fetch user

        case 'FETCH_USER_DATA_SUCCESS':
            return {
                ...state,
                fetchUserData: rest.payload
            }

        // update user

        case 'UPDATE_USER_DATA_SUCCESS':
            return {
                ...state,
                updateUserData: rest.payload
            }

        // delete user

        case 'DELETE_USER_DATA_SUCCESS':
            return {
                ...state,
                deleteUserData: rest.payload
            }


        // get customer list

        case 'GET_CUSTOMER_LIST_DATA_SUCCESS':
            return {
                ...state,
                getCustomerListData: rest.payload
            }


        //create customer

        case 'CREATE_CUSTOMER_DATA_SUCCESS':
            return {
                ...state,
                createCustomerData: rest.payload
            }

        //fetch customer

        case 'FETCH_CUSTOMER_DATA_SUCCESS':
            return {
                ...state,
                fetchCustomerData: rest.payload
            }

        // update customer

        case 'UPDATE_CUSTOMER_DATA_SUCCESS':
            return {
                ...state,
                updateCustomerData: rest.payload
            }

        // delete customer

        case 'DELETE_CUSTOMER_DATA_SUCCESS':
            return {
                ...state,
                deleteCustomerData: rest.payload
            }


        // sales order module

        case 'GET_SALESORDER_LIST_DATA_SUCCESS':
            return {
                ...state,
                getSalesOrderListData: rest.payload
            }

        case 'CREATE_SALESORDER_DATA_SUCCESS':
            return {
                ...state,
                createSalesOrderData: rest.payload
            }

        case 'FETCH_SALESORDER_DATA_SUCCESS':
            return {
                ...state,
                fetchSalesOrderData: rest.payload
            }

        case 'UPDATE_SALESORDER_DATA_SUCCESS':
            return {
                ...state,
                updateSalesOrderData: rest.payload
            }

        case 'DELETE_SALESORDER_DATA_SUCCESS':
            return {
                ...state,
                deleteSalesOrderData: rest.payload
            }

        case 'DELETE_ITEM_DATA_SUCCESS':
            return {
                ...state,
                deleteItemData: rest.payload
            }

        case 'GET_PREVIEW_PDF_SUCCESS':
            return {
                ...state,
                getPreviewPdfData: rest.payload
            }


        //get customer 

        case 'GET_CUSTOMER_SUCCESS':
            return {
                ...state,
                getCustomer: rest.payload
            }

        case 'GET_SALES_PERSON_SUCCESS':
            return {
                ...state,
                getSalesPersonData: rest.payload
            }

        //filter Data

        case 'FILTER_DATA':
            return {
                ...state,
                filter: rest.payload
            }

        case 'UPLOAD_FILE_DATA_SUCCESS':
            return {
                ...state,
                uploadFile: rest.payload
            }

        case 'DELETE_FILE_DATA_SUCCESS':
            return {
                ...state,
                deleteFile: rest.payload
            }

        case 'DOWNLOAD_FILE_DATA_SUCCESS':
            return {
                ...state,
                downloadFile: rest.payload
            }

        default:
            return state
    }
}