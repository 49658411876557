import { Navigate } from "react-router-dom"

var API_URL = process.env.REACT_APP_END_POINT

var Auth_Key = localStorage.getItem('authToken') !== null ? localStorage.getItem('authToken') : null

const checkIsValidToken = (history) => {
    if (localStorage.getItem('authToken') === null) {
        history.push({ pathname: '/' })
        window.location.reload()
    }
    else {
        Auth_Key = localStorage.getItem('authToken') !== null ? localStorage.getItem('authToken') : null
    }
}

const Authorization = (history) => {
    localStorage.clear();
    history.push({ pathname: '/' })
    window.location.reload()
}

export { API_URL, Auth_Key, Authorization, checkIsValidToken }