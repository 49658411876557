import React, { useReducer, useRef, useState } from 'react'
import {
    CButton, CForm, CFormInput, CFormLabel, CFormTextarea, CDatePicker, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CInputGroup, CInputGroupText
} from '@coreui/react'
import { useNavigate } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { resetLoginPassword, updateForgetPassword } from '../store/actions';
import { Auth_Key } from '../helpers/authToken';
import { cisEye, cisEyeSlash } from '@coreui/icons-pro';
import CIcon from '@coreui/icons-react';

const initialState = {
    resetPass: {
        email: localStorage.getItem("email") !== null ? localStorage.getItem("email") : "",
        newpassword: "",
        conpassword: ""
    },
};
const reducer = (state, payload) => ({ ...state, ...payload });

const ResetPassword = () => {

    const [resetPasslogin, setResetPassLogin] = useReducer(reducer, initialState);
    const { resetPass } = resetPasslogin;
    const validator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    const [newPassText, setNewPassText] = useState(false)
    const [conPassText, setConPassText] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const app = useSelector(state => state.app)
    let pid = window.location.pathname.split("/")[2];

    const handleResetChange = (e) => {
        const { name, value } = e.target;

        setResetPassLogin({
            ...resetPasslogin,
            resetPass: {
                ...resetPasslogin.resetPass,
                [name]: value
            }
        })
    }

    const onSave = () => {
        let sendReq = {
            resetPasswordToken: pid,
            newPassword: resetPass.newpassword,
            confirmPassword: resetPass.conpassword
        }
        console.log('sendReq', sendReq)
        if (validator.current.allValid()) {
            if (resetPass.newpassword === resetPass.conpassword) {
                updateForgetPassword(sendReq, (res) => {
                    if (res.flag) {
                        toast.success(res.message, { position: toast.POSITION.TOP_RIGHT, });
                        setTimeout(() => {
                            navigate('/')
                        }, 2000);
                    } else {
                        toast.error(res.message, { position: toast.POSITION.TOP_RIGHT, });
                    }
                })(dispatch)
            } else {
                toast.error("Your password and confirmation password do not match.", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } else {
            validator.current.showMessages();
            forceUpdate(1);
        }
    }


    return (
        <div style={{ maxWidth: "600px", margin: "0 auto", background: "#ebedef6e", padding: "40px",border: "1px solid #bcbebf99",marginTop: "110px", }}>
            <h5 style={{ marginBottom: "20px" }} >Reset Password</h5>
            <CForm>
                {/* <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Email address</CFormLabel>
                    <CFormInput placeholder="Email address" autoComplete="username" name="email" value={resetPass.email} disabled />
                    <span style={{ color: 'red' }}>{validator.current.message('email', resetPass.email, 'required|email')}</span>
                </div> */}
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">New Password</CFormLabel>
                    <div className='form-group'>
                        <CFormInput
                            type={newPassText ? "text" : "password"}
                            placeholder="New password"
                            name="newpassword"
                            value={resetPass.newpassword}
                            onChange={handleResetChange}
                            autoComplete="current-password"
                        />
                        {newPassText ? <CIcon class='shorting_icon' icon={cisEye} customClassName="nav-icon" onClick={() => setNewPassText(!newPassText)} /> :
                            <CIcon class='shorting_icon' icon={cisEyeSlash} customClassName="nav-icon" onClick={() => setNewPassText(!newPassText)} />
                        }
                    </div>

                    <span style={{ color: 'red' }}>{validator.current.message('new password', resetPass.newpassword, 'required')}</span>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Confirm Password</CFormLabel>
                    <div className='form-group'>
                        <CFormInput
                            type={conPassText ? "text" : "password"}
                            placeholder="Confirm password"
                            name="conpassword"
                            value={resetPass.conpassword}
                            onChange={handleResetChange}
                            autoComplete="current-password"
                        />
                        {
                            conPassText ? <CIcon class='shorting_icon' icon={cisEye} customClassName="nav-icon" onClick={() => setConPassText(!conPassText)} /> :
                                <CIcon class='shorting_icon' icon={cisEyeSlash} customClassName="nav-icon" onClick={() => setConPassText(!conPassText)} />
                        }
                    </div>
                    <span style={{ color: 'red' }}>{validator.current.message('confirm password', resetPass.conpassword, 'required')}</span>
                </div>
            </CForm>
            <CButton color="primary" style={{ marginRight: "6px" }} onClick={() => onSave()}>Save</CButton>
            <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={8000} />
        </div>
    )

}

export default ResetPassword