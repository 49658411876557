import { Navigate } from "react-router-dom";
import { Auth_Key } from "./helpers/authToken";

export const ProtectedRoute = ({ children }) => {
    let token = localStorage.getItem('authToken')
    if (!token) {
        // user is not authenticated
        return <Navigate to="/" />;
    } else {
        return children;
    }
};