import React, { Component, useEffect, useReducer, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CRow,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilLockLocked, cilUser } from '@coreui/icons';
import SimpleReactValidator from 'simple-react-validator';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { login } from '../store/actions'

const initialState = {
    email: "",
    password: ""
}

const reducer = (state, payload) => ({ ...state, ...payload });

function Login() {
    const [data, setData] = useReducer(reducer, initialState);
    const { email, password } = data;
    const validator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loginData: _LOGIN, loginDataErr: _LOGINERR } = useSelector((state) => state.app)
    // let userToken = localStorage.getItem('authToken')
    // useEffect(() => {
    //     if (userToken) {
    //         navigate(-1)
    //     }
    // }, [])
    
    useEffect(() => {
        if (_LOGIN) {
            if (_LOGIN.flag) {
                toast.success(_LOGIN.message, { position: toast.POSITION.TOP_RIGHT, });
                setTimeout(function () {
                    if (localStorage.getItem('role') === "staff") {
                        navigate('/salesorder')
                    } else {
                        navigate('/user')
                    }
                    // window.location.reload();
                }, 1000);
            }
            dispatch({
                type: "LOGIN_SUCCESS",
                payload: null,
            });
        }
    }, [_LOGIN])

    useEffect(() => {
        if (_LOGINERR) {
            toast.error(_LOGINERR, { position: toast.POSITION.TOP_RIGHT, });
            dispatch({
                type: "LOGIN_ERROR",
                error: null,
            });
        }
    }, [_LOGINERR])


    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        })
    }

    const onLogin = async (e) => {
        e.preventDefault();
        if (validator.current.allValid()) {
           await login(data, (res) => {
                localStorage.setItem("User", JSON.stringify({ name: res.result.data.name, number: res.result.data.number, email: res.result.data.email,_id:res.result.data._id }))
            })(dispatch)
            // navigate('/user')
        } else {
            validator.current.showMessages(true);
            forceUpdate(1);
        }
    }

    const handleForgotPassword = () => {
        navigate('/forget')
    }

    return (
        <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md={8}>
                        <CCardGroup style={{ maxWidth: "500px", margin: "0 auto" }}>
                            <CCard className="p-4">
                                <CCardBody>
                                    <CForm>
                                        <h1>Login</h1>
                                        <p className="text-medium-emphasis">Sign In to your account</p>
                                        <CInputGroup className="mt-3">
                                            <CInputGroupText>
                                                <CIcon icon={cilUser} />
                                            </CInputGroupText>
                                            <CFormInput placeholder="Username" name="email" value={email} onChange={handleChange} />
                                        </CInputGroup>
                                        <span style={{ color: 'red', fontSize: '14px' }}>{validator.current.message('username', email, 'required')}</span>
                                        <CInputGroup className="mt-3">
                                            <CInputGroupText>
                                                <CIcon icon={cilLockLocked} />
                                            </CInputGroupText>
                                            <CFormInput
                                                type="password"
                                                placeholder="Password"
                                                name="password"
                                                value={password}
                                                onChange={handleChange}
                                            />
                                        </CInputGroup>
                                        <span style={{ color: 'red', fontSize: '14px' }}>{validator.current.message('password', password, 'required')}</span>
                                        <CRow className='mt-3'>
                                            <CCol xs={6}>
                                                <button className="btn btn-primary px-4" onClick={onLogin}>
                                                    Login
                                                </button>
                                            </CCol>
                                            <CCol xs={6} className="text-right">
                                                <CButton color="link" className="px-0" onClick={handleForgotPassword}>
                                                    Forgot password?
                                                </CButton>
                                            </CCol>
                                        </CRow>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                            {/* <CCard className="text-white bg-primary py-5" style={{ width: '44%' }}>
                                    <CCardBody className="text-center">
                                        <div>
                                            <h2>Sign up</h2>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                                tempor incididunt ut labore et dolore magna aliqua.
                                            </p>
                                            <Link to="/register">
                                                <CButton color="primary" className="mt-3" active tabIndex={-1}>
                                                    Register Now!
                                                </CButton>
                                            </Link>
                                        </div>
                                    </CCardBody>
                                </CCard> */}
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
            <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={8000} />
        </div>
    )
}


export default Login;