import axios from "axios";
import { API_URL, Auth_Key } from "../helpers/authToken";

const api = axios.create();

const statusCheck = {
    validateStatus: (status) => {
        if (status === 401) {
            // window.location.reload(true)
        } else {
            return true;
        }
    }
}

function onError(response) {
    return response;
}

function onSuccess(response) {
    return response;
}

api.defaults.baseURL = API_URL;

export const setToken = (token) =>{
    api.defaults.headers.common['x-auth-token'] = token // 4e5613a8215d6f50d4fbb9e351d2c56f
}
 const getToken = ()=>{
    return localStorage.getItem('authToken')
 }

api.defaults.headers.common['x-auth-token'] = getToken();
// api.defaults.headers.common['x-auth-token'] = "4e5613a8215d6f50d4fbb9e351d2c56f"; // 4e5613a8215d6f50d4fbb9e351d2c56f
api.defaults.headers.post["Content-Type"] = "application/json";
api.defaults.headers.post["Accept"] = "application/json";
api.defaults.headers["Cache-Control"] = "no-cache";

export const dashboardService = {
    //login
    userLoginData: data => api.post("user/login", data, statusCheck).then(onSuccess, onError),

    //logout
    logoutData: () => api.post("user/logout", statusCheck).then(onSuccess, onError),

    //edit profile
    updateUserProfileData: data => api.post("user/edit/profile", data, statusCheck).then(onSuccess, onError),

    //change password
    resetPasswordData: data => api.post("user/change/password", data, statusCheck).then(onSuccess, onError),

    //reset password
    updatePasswordData:data => api.post("user/reset/password", data, statusCheck).then(onSuccess, onError),

    //user module
    userData: data => api.post("user/getAllUser", data, statusCheck).then(onSuccess, onError),
    createUserData: data => api.post("user/addUser", data, statusCheck).then(onSuccess, onError),
    fetchUserData: (id) => api.get(`user/getSingleUser/${id}`, statusCheck).then(onSuccess, onError),
    updateUserData: (data, id) => api.post(`user/updateUser/${id}`, data, statusCheck).then(onSuccess, onError),
    deleteUserData: (id) => api.delete(`user/deleteUser/${id}`, statusCheck).then(onSuccess, onError),

    //customer module
    customerData: data => api.post("customer/getAllCustomer", data, statusCheck).then(onSuccess, onError),
    createCustomerData: data => api.post("customer/addCustomer", data, statusCheck).then(onSuccess, onError),
    fetchCustomerData: (id) => api.get(`customer/getSingleCustomer/${id}`, statusCheck).then(onSuccess, onError),
    updateCustomerData: (data, id) => api.post(`customer/updateCustomer/${id}`, data, statusCheck).then(onSuccess, onError),
    deleteCustomerData: (id) => api.delete(`customer/deleteCustomer/${id}`, statusCheck).then(onSuccess, onError),

    //sales order module
    salesOrderData: data => api.post("orders/get/orderList", data, statusCheck).then(onSuccess, onError),
    createSalesOrderData: data => api.post("orders/add/newOrder", data, statusCheck).then(onSuccess, onError),
    fetchSalesOrderData: (id) => api.get(`orders/getSingleOrder/${id}`, statusCheck).then(onSuccess, onError),
    updateSalesOrderData: (data, id) => api.post(`orders/edit/order/${id}`, data, statusCheck).then(onSuccess, onError),
    deleteSalesOrderData: (data) => api.post("orders/deleteOrder", data, statusCheck).then(onSuccess, onError),
    deleteItemData: (data) => api.post("orders/delete/item", data, statusCheck).then(onSuccess, onError),
    getPreviewData: id => api.post(`orders/pdf/${id}`, statusCheck).then(onSuccess, onError),


    getCustomer: () => api.get("orders/customerList", statusCheck).then(onSuccess, onError),
    getSalesPerson: () => api.get("orders/salesPersonList", statusCheck).then(onSuccess, onError),

    uploadFile: (data, documentType) => api.post(`fileUpload/upload?documentType=${documentType}`, data, statusCheck).then(onSuccess, onError),
    deleteFile:(id) => api.delete(`fileUpload/delete/${id}`, statusCheck).then(onSuccess, onError),
    downloadFile:(id) => api.get(`fileUpload/${id}`, statusCheck).then(onSuccess, onError),

    forgetPasswordData: data => api.post("user/forget/password", data, statusCheck).then(onSuccess, onError),
};
